import _Vue from "vue";
import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app";
import { Analytics, getAnalytics } from "firebase/analytics";
import {
  getMessaging,
  getToken,
  Messaging,
  onMessage,
  isSupported,
} from "firebase/messaging";
import logger from "@/utils/logger";
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
} from "@capacitor/push-notifications";
import { Device } from "@capacitor/device";
import { useProfileStore } from "@/store/profile";
import { storageLib } from "@/utils/storage";
import { useNotificationHook } from "@/utils/notifications";

import { useToastStore } from "@/store/toastStore";
import { useNotificationStore } from "@/store/notifications";
import router from "@/router";

export interface fbHookType {
  fbConfig: FirebaseOptions;
  getFbApp: () => FirebaseApp | null;
  getFbMessaging: () => Messaging | null;
  initWebApp: () => void;
  subscribeOnMessage: () => void;
  fbAnalytics: Analytics | null;
  getTokenAsync: (
    serviceWorkerRegistration: ServiceWorkerRegistration
  ) => Promise<string>;
  getPWAToken: () => Promise<string | null>;
  isSupported: () => Promise<boolean>;
  addMobileListeners: () => Promise<void>;
}

export const useFirebaseHook = (): fbHookType => {
  const notificationStore = useNotificationStore();
  const profileStore = useProfileStore();
  const notificationHook = useNotificationHook();
  const toastStore = useToastStore();
  const vapidKey =
    "BIfXaNsAsb8V27QkxrJHyOy04F2VdwrRej0qZwiFfRrXSoPtabqkaNcruqIkTUP1fCwaYEZsOpAx9chWeSr9JmI";
  const firebaseConfig = {
    apiKey: "AIzaSyCknTx84YtOXzp5dcpWVjGUMQeWs2YaW9E",
    authDomain: "gear-guru-64a31.firebaseapp.com",
    projectId: "gear-guru-64a31",
    storageBucket: "gear-guru-64a31.appspot.com",
    messagingSenderId: "367122698611",
    appId: "1:367122698611:web:4b24c2d03bccde9f7c7506",
    measurementId: "G-6NWSQHBRQ6",
  };

  let firebaseApp: FirebaseApp | null = null;
  let messaging: Messaging | null = null;
  let analytics: Analytics | null = null;

  const initWebApp = () => {
    firebaseApp = initializeApp(firebaseConfig);
    messaging = getMessaging(firebaseApp);
    analytics = getAnalytics(firebaseApp);
    logger.log("Init test: ", firebaseApp, messaging);
    subscribeOnMessage();
    //
  };
  const getPWAToken = async (): Promise<string | null> => {
    const temp = await navigator.serviceWorker.getRegistration();
    let token: string | null = null;

    logger.log("temp: ", temp);
    if (temp) {
      logger.log("Requesting permission...");
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        logger.log("Notification permission granted.");
        initWebApp();
        token = await getTokenAsync(temp);
      } else {
        logger.log("Notification permission denied.");
        token = null;
      }
    } else {
      logger.log("No registration for service worker");
      token = null;
    }
    return Promise.resolve(token);
  };

  const subscribeOnMessage = (): void => {
    const msg = getFbMessaging();
    if (msg == null) {
      logger.error("Cannot subscribe to on message == null");
      return;
    }
    onMessage(msg, async (payload) => {
      logger.log("Message received FB. ", payload);
      if (payload.notification && payload.notification.title) {
        toastStore.addToast("info", payload.notification.title);
      }
      // NotificationSystem.addToUnreadNotifications({
      //   title: payload.notification?.title || "Title Missing",
      //   id: Number(payload.messageId),
      //   body: payload.notification?.body || "Text missing",
      //   date: new Date().toDateString(),
      //   time: new Date().toTimeString(),
      // });

      if (profileStore.profile) {
        await notificationStore.getNotificationsCount(profileStore.profile.id);
      }
      if (router.currentRoute.value.name == "Notifications") {
        await notificationStore.getNotificationFromApi();
      }
    });
  };
  // const analytics = getAnalytics(firebaseApp);
  const getFbApp = (): FirebaseApp | null => {
    return firebaseApp;
  };
  const getFbMessaging = (): Messaging | null => {
    return messaging;
  };

  const getTokenAsync = async (
    serviceWorkerRegistration: ServiceWorkerRegistration
  ): Promise<string> => {
    try {
      const msg = getFbMessaging();
      if (msg == null) {
        logger.log("Firebase Messaging == null");
        return Promise.reject("Firebase Messaging == null");
      }
      logger.log("Going to get token", serviceWorkerRegistration);
      const token = await getToken(msg, {
        vapidKey: vapidKey,
        serviceWorkerRegistration: serviceWorkerRegistration,
      }).catch((err) => {
        logger.log("GET_TOKEN err: ", err);
        return Promise.reject(err);
      });

      if (token) {
        logger.log("Registration Token Available", token);
      } else {
        logger.log(
          "No registration token available. Request permission to generate one."
        );
      }
      return Promise.resolve(token);
    } catch (err) {
      logger.log("GET_TOKEN_ASYNC err: ", err);
      return Promise.reject(err);
    }
  };
  const addMobileListeners = async () => {
    await PushNotifications.addListener("registration", async (token) => {
      logger.info(
        "Registration token: ",
        token.value,
        profileStore.getProfile()
      );
      await storageLib.setFcmToken(token.value);
      notificationStore.setFirebaseToken(token.value);
      //set token in local storage/vuex
      // if user doPushNotifications
      if (profileStore.getProfile() != null) {
        logger.log("doPushNotif parent block");
        await notificationHook.doPushNotifications(token.value);
      } else {
        // this should not happen though test
        // NotificationLib.createWarningNotification(
        //   `Does not register after getProfile. ${token.value}`
        // );
      }
    });

    await PushNotifications.addListener("registrationError", (err) => {
      logger.error("Registration error: ", err.error);
      notificationHook.createErrorNotification(
        "Mobile Push notification registration failed. Contact the dev team."
      );
    });
    // Show us the notification payload if the app is open on our device
    await PushNotifications.addListener(
      "pushNotificationReceived",
      async (notification: PushNotificationSchema) => {
        logger.log(
          "Notification in foreground: ",
          JSON.stringify(notification)
        );
        // vibrate

        // create notification based on X Y Z
        const dev = await Device.getInfo();
        if (dev) {
          if (dev.platform == "android") {
            logger.log("Android 11 and up", dev.osVersion);
            const temp = Number(dev.osVersion);
            if (dev.osVersion && Number.isNaN(temp) == false && temp >= 11) {
              return Promise.resolve();
            }
          }
        }

        return Promise.resolve();
      }
    );
    // Method called when tapping on a notification
    await PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notification: ActionPerformed) => {
        logger.log(
          "Push notification action performed",
          notification.actionId,
          notification.inputValue
        );
      }
    );
  };
  return {
    fbConfig: firebaseConfig,
    getFbMessaging,
    getFbApp,
    initWebApp,
    subscribeOnMessage,
    fbAnalytics: analytics,
    getTokenAsync,
    isSupported,
    getPWAToken,
    addMobileListeners,
  };
};
export default { useFirebaseHook };
