import { defineStore } from "pinia";
import { Ref, computed, ref } from "vue";
import { ApplicationUser } from "@/models/user";
import { RemovableRef, useSessionStorage } from "@vueuse/core";

export const useProfileStore = defineStore("profileStore", () => {
  //const profile: Ref<ApplicationUser | null> = ref(null);
  const userProfile: RemovableRef<ApplicationUser | null> = useSessionStorage(
    "profile",
    {} as RemovableRef<ApplicationUser | null>
  );
  const profile = computed(() => {
    return userProfile.value;
  });

  function setProfile(prof: ApplicationUser | null) {
    userProfile.value = prof;
  }

  function getProfile() {
    return userProfile.value;
  }

  return {
    setProfile,
    getProfile,
    userProfile,
    profile
  };
});
