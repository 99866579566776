/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import DayJsAdapter from "@date-io/dayjs";

// Composables
import { createVuetify } from "vuetify";

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  date: {
    adapter: DayJsAdapter,
  },
  theme: {
    themes: {
      light: {
        colors: {
          primary: "#FDA214",
          "primary-darken": "#ca7d02",
          secondary: "#EC7103",
          accent: "#00C5DC",
          gray: "#ededed",
          "gray-darken": "#bfbfbf"
          /*  grey: "#ededed", */
        },
      },
    },
  },
});
