import {
  BookingNotificationLog,
  UserNotifications,
} from "@/models/notifications";
import { Products, ProductsViewModel } from "@/models/product";
import { ApplicationUser, UserViewModel } from "@/models/user";
import { WalletHistoryViewModel, WalletTopUpViewModel } from "@/models/wallet";
import { privateAPI, publicAPI } from "@/plugins/axios";

/* TODO@KP: Check out return models and any types */
const UserAPI = () => {
  const DeleteListing = async (listingId: number): Promise<any> => {
    const res = await privateAPI.get(
      `/api/User/DeleteListing?listingId=${listingId}`
    );
    return Promise.resolve(res.data);
  };

  const GetAllNotifictions = async (): Promise<UserNotifications[]> => {
    const res = await privateAPI.get(`/api/User/GetAllNotifictions`);
    return Promise.resolve(res.data);
  };

  const GetCurrentUser = async (): Promise<ApplicationUser> => {
    const res = await privateAPI.get(`/api/User/GetCurrentUser`);
    return Promise.resolve(res.data);
  };

  const UpdateUserProfile = async (data: ApplicationUser): Promise<any> => {
    const res = await privateAPI.post(`/api/User/UpdateUserProfile`, data);
    return Promise.resolve(res.data);
  };

  const DeleteUserById = async (Id: number): Promise<any> => {
    const res = await publicAPI.get(`/api/User/DeleteUserById?Id=${Id}`);
    return Promise.resolve(res.data);
  };

  const CreateListing = async (data: ProductsViewModel): Promise<any> => {
    const res = await privateAPI.post(`/api/User/CreateListing`, data);
    return Promise.resolve(res.data);
  };

  const EditListing = async (data: ProductsViewModel): Promise<any> => {
    const res = await privateAPI.post(`/api/User/EditListing`, data);
    return Promise.resolve(res.data);
  };

  const GetAllUserListedItems = async (): Promise<ProductsViewModel[]> => {
    const res = await privateAPI.get(`/api/User/GetAllUserListedItems`);
    return Promise.resolve(res.data);
  };

  const GetWalletBalance = async (): Promise<number> => {
    const res = await privateAPI.get(`/api/User/GetWalletBalance`);
    return Promise.resolve(res.data);
  };

  const GetUserByID = async (id: string): Promise<UserViewModel> => {
    const res = await privateAPI.get(`/api/User/GetUserByID?Id=${id}`);
    return Promise.resolve(res.data);
  };

  const DeleteUser = async (): Promise<boolean> => {
    const res = await privateAPI.get(`/api/User/DeleteUser`);
    return Promise.resolve(res.data);
  };

  const UserDeleteCheck = async (): Promise<boolean> => {
    const res = await privateAPI.get(`/api/User/UserDeleteCheck`);
    return Promise.resolve(res.data);
  };

  const GetWalletHistory = async (): Promise<WalletHistoryViewModel[]> => {
    const res = await privateAPI.get(`/api/User/GetWalletHistory`);
    return Promise.resolve(res.data);
  };

  const GetWalletByUserId = async (userId: string): Promise<UserViewModel> => {
    const res = await privateAPI.get(
      `/api/User/GetWalletByUserId?userId=${userId}`
    );
    return Promise.resolve(res.data);
  };

  const TopUpWallet = async (
    data: WalletTopUpViewModel
  ): Promise<WalletHistoryViewModel[]> => {
    const res = await privateAPI.post(`/api/User/TopUpWallet`, data);
    return Promise.resolve(res.data);
  };

  return {
    DeleteListing,
    GetAllNotifictions,
    GetCurrentUser,
    UpdateUserProfile,
    DeleteUserById,
    EditListing,
    CreateListing,
    GetAllUserListedItems,
    GetWalletBalance,
    GetUserByID,
    DeleteUser,
    UserDeleteCheck,
    GetWalletHistory,
    GetWalletByUserId,
    TopUpWallet,
  };
};

const userAPI = UserAPI();
export default userAPI;
