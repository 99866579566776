<template>
  <v-snackbar
    location="right top"
    v-if="toastStore.currentToast"
    :key="toastStore.currentToast.id"
    :model-value="toastStore.currentToast.showing"
    :color="toastStore.currentToast.type"
    :timeout="3000"
    @update:modelValue="toastStore.removeToast(toastStore.currentToast.id)"
    class="toast"
  >
    <template v-slot:actions>
      <v-btn
        color="white"
        variant="text"
        @click="toastStore.removeToast(toastStore.currentToast.id)"
      >
        Close
      </v-btn>
    </template>
    <div class="text-break text-white">
      {{ toastStore.currentToast.message }}
    </div>
  </v-snackbar>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { useToastStore } from "@/store/toastStore";

const toastStore = useToastStore();

const toasts = computed(() => toastStore.getToasts);
</script>

<style scoped>
.toast {
  /* top: 2px !important; */
  --v-layout-top: 2dvh !important;
  z-index: 3000 !important;
}
</style>
