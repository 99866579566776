import { defineStore } from "pinia";
import { Ref, computed, ref } from "vue";
import { ApplicationUser } from "@/models/user";
import { RemovableRef, useSessionStorage } from "@vueuse/core";
import { DeviceInfo } from "@capacitor/device";
import { privateAPI } from "@/plugins/axios";
import liveChatAPI from "@/api/liveChat";
import { ChatPrompts } from "@/models/chat";
import userAPI from "@/api/user";
import {
  BookingNotificationLog,
  UserNotifications,
} from "@/models/notifications";

interface ApiNotification {
  body: string;
  title?: string;
  id: number;
  time: string;
  date: string;
}

export const useNotificationStore = defineStore("notificationStore", () => {
  const deviceInfo = ref<DeviceInfo | null>(null);
  const firebaseToken = ref<string | null>(null);
  const apiNotifications = ref<UserNotifications[]>([]);
  //const unreadNotifications = ref<ApiNotification[]>([]);
  const notificationCount = ref<Number>(0);
  const chatPrompts = ref<ChatPrompts[]>([]);
  const chatProgress = ref<{
    addressConfirmed: boolean;
    timeConfirmed: boolean;
    productDelivered: boolean;
    productReceived: boolean;
    productReturned: boolean;
    onMyWay: boolean;
    arrived: boolean;
    minutesAwayConfirmed: boolean;
    returnAddressConfirmed: boolean;
    returnTimeConfirmed: boolean;
  }>({
    addressConfirmed: false,
    productDelivered: false,
    productReceived: false,
    productReturned: false,
    timeConfirmed: false,
    arrived: false,
    onMyWay: false,
    minutesAwayConfirmed: false,
    returnAddressConfirmed: false,
    returnTimeConfirmed: false,
  });

  function setFirebaseToken(token: string | null) {
    firebaseToken.value = token;
  }

  /* function clearUnreadNotifications() {
    unreadNotifications.value.splice(0);
  } */

  function setChatProgress(data: {
    addressConfirmed: boolean;
    timeConfirmed: boolean;
    productDelivered: boolean;
    productReceived: boolean;
    productReturned: boolean;
    onMyWay: boolean;
    arrived: boolean;
    minutesAwayConfirmed: boolean;
    returnAddressConfirmed: boolean;
    returnTimeConfirmed: boolean;
  }) {
    chatProgress.value = data;
  }

  async function getNotificationFromApi() {
    const res = await userAPI.GetAllNotifictions();

    apiNotifications.value = res;
    apiNotifications.value.map((item) => {
      (item as any).showMore = false;
    });
    return Promise.resolve();
  }

  async function getNotificationsCount(userId: string) {
    const res = await liveChatAPI.GetNotificationCounts(userId);
    notificationCount.value = res;
    return Promise.resolve();
  }

  async function getChatPrompts() {
    const res = await liveChatAPI.GetAllPrompts();
    chatPrompts.value = res;
    return Promise.resolve();
  }

  return {
    //clearUnreadNotifications,
    getNotificationFromApi,
    getNotificationsCount,
    setFirebaseToken,
    getChatPrompts,
    setChatProgress,
    firebaseToken,
    deviceInfo,
    apiNotifications,
    //unreadNotifications,
    notificationCount,
    chatPrompts,
    chatProgress,
  };
});
