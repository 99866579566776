/* import authAPI from "@/api/auth";
import { Auth, Login, TokenResponse } from "@/models/Auth"; */
import { RemovableRef, useSessionStorage } from "@vueuse/core";
import { defineStore } from "pinia";
import { ComputedRef, computed, reactive } from "vue";
import { useProfileStore } from "./profile";
import { LoginViewModel } from "@/models/auth";
import loginAPI from "@/api/login";

export const useAuthenticationStore = defineStore("authenticationStore", () => {
  const profileStore = useProfileStore();
  // getters
  const authentication: RemovableRef<{
    access_token: string;
    username: string;
  }> = useSessionStorage(
    "authentication",
    {} as RemovableRef<{
      access_token: string;
      username: string;
    }>
  );
  const authenticationToken: ComputedRef<string | null> = computed(() => {
    return authentication.value ? authentication.value.access_token : null;
  });
  const authenticated = computed(() => {
    return authenticationToken.value != null;
  });
  const loginOpen = computed(() => {
    return dialog.openLogin;
  });
  const registerOpen = computed(() => {
    return dialog.openRegister;
  });
  const dialog = reactive<{
    openRegister: boolean;
    openLogin: boolean;
  }>({
    openRegister: false,
    openLogin: false,
  });
  // actions + mutations
  async function login(credentials: LoginViewModel): Promise<
    | {
        access_token: string;
        username: string;
      }
    | string
  > {
    try {
      const resToken = await loginAPI.Login(credentials);
      //console.log("getToken Resp: ", resToken);

      authentication.value = {
        access_token: resToken.message,
        username: credentials.userName!,
      };
      return Promise.resolve(authentication.value);
    } catch (err) {
      return Promise.reject(err);
    }
  }
  function logout(): void {
    profileStore.setProfile(null);
    authentication.value = null;
    //    console.log("Unauthenticated", authentication.value);
  }
  function setLoginDialogOpenState(open: boolean) {
    dialog.openLogin = open;
  }
  function setRegisterDialogOpenState(open: boolean) {
    dialog.openRegister = open;
  }
  return {
    authentication,
    authenticated,
    authenticationToken,
    loginOpen,
    registerOpen,
    login,
    logout,
    setLoginDialogOpenState,
    setRegisterDialogOpenState,
  };
});
