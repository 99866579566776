import _Vue from "vue";
import { Device, DeviceId, DeviceInfo } from "@capacitor/device";
import { storageLib } from "@/utils/storage";
import { PushNotifications } from "@capacitor/push-notifications";
import { privateAPI } from "@/plugins/axios";
import logger from "@/utils/logger";
import loginAPI from "@/api/login";

const delay = 100;

export interface notificationType {
  // type: NOTIFICATION;
  timeout: number;
  title?: string;
  message: string;
  color: string;
  type: "APP" | "FCM";
  icon: string;
  id: number | string; // String to be preferred as one can specify the are of code that this originates from.
  cb?: () => void;
}

const useNotificationHook = () => {
  const createAlarmNotification = (notif: {
    title: string | undefined;
    body: string | undefined;
    id: string;
    cb?: () => void;
  }): notificationType => {
    const temp: notificationType = {
      color: "white",
      title: notif.title,
      message: notif.body != undefined ? notif.body : "Message not loaded",
      icon: "mdi-bell",
      timeout: 3000,
      id: notif.id,
      cb: notif.cb,
      type: "FCM",
    };
    logger.log("Create alarm notif ", temp);
    // NotificationSystem.addNotifAndTimeoutCheck(temp);
    return temp;
  };
  const createSuccessNotification = (message: string): notificationType => {
    const temp: notificationType = {
      color: "success",
      message: message,
      icon: "mdi-check-all",
      timeout: 4000,
      id: Math.ceil(Math.random() * 1000),
      type: "APP",
    };
    logger.log("Create success notif ", temp);
    // NotificationSystem.addNotifAndTimeoutCheck(temp);
    return temp;
  };
  // const createPushNotification = (message: string): notificationType => {
  //   const temp: notificationType = {
  //     color: "white",
  //     message: message,
  //     icon: "mdi-bell",
  //     timeout: 4000,
  //     id: Math.ceil(Math.random() * 1000),
  //     type: "FCM"
  //   };
  //   NotificationSystem.addNotifAndTimeoutCheck(temp);
  //   return temp;
  // };
  const createErrorNotification = (message: string): notificationType => {
    const temp: notificationType = {
      color: "error",
      message: message,
      icon: "mdi-alert-circle",
      timeout: 10000,
      id: Math.ceil(Math.random() * 1000),
      type: "APP",
    };
    logger.log("Create error notif ", temp);
    // NotificationSystem.addNotifAndTimeoutCheck(temp);
    return temp;
  };
  const createWarningNotification = (message: string): notificationType => {
    const temp: notificationType = {
      color: "warning",
      message: message,
      icon: "mdi-shield-alert",
      timeout: 10000,
      id: Math.ceil(Math.random() * 1000),
      type: "APP",
    };
    logger.log("Create warn notif ", temp);
    // NotificationSystem.addNotifAndTimeoutCheck(temp);
    return temp;
  };

  const registerMobileFirebaseNotifications = async () => {
    createWarningNotification("Checking permisssions");
    logger.log("Checking permisssions");
    let permStatus = await PushNotifications.checkPermissions();
    if (permStatus.receive === "prompt") {
      logger.log("Requesting permission here");
      permStatus = await PushNotifications.requestPermissions();
    }
    logger.warn("Register fcm ", permStatus);
    // NotificationLib.createWarningNotification(`PErm status: ${permStatus}`);
    if (permStatus.receive !== "granted") {
      // rejected permissions
      return Promise.resolve();
    }

    return await PushNotifications.register();
  };
  const registerWebFirebaseNotifications = async () => {
    // NotificationLib.createWarningNotification("Checking web permisssions");
    logger.log("Checking permisssions");
    const permStatus = await Notification.requestPermission();
    if (permStatus === "granted") {
      // NotificationLib.createSuccessNotification("Permission granted");
    } else if (permStatus === "denied") {
      createWarningNotification("You will not get notifications");
    }
    logger.warn("Register fcm ", permStatus);
    return Promise.resolve();
  };

  const registerFcmOnServer = async (
    token: string,
    devInfo: DeviceInfo,
    deviceId: DeviceId
  ) => {
    /* const res = await privateAPI.get(
      `/api/Login/SyncDeviceTokens?token=${token}&deviceId=${deviceId.identifier}`
    ); */
    const res = await loginAPI.SyncDeviceTokens(token);
  };
  const deregisterFcmOnServer = async () => {
    const token = await storageLib.getFcmToken();
    if (token == null) {
      // NotificationLib.createWarningNotification("No FCM token to deregister");
      return Promise.resolve();
    }

    /*  const res = await privateAPI.get(
      "/api/Authenticate/removeDeviceTokens",
      {
        params: {
          token: token,
        },
      }
    ); */
    return Promise.resolve();
  };
  const doPushNotifications = async (token: string) => {
    const devInfo = await Device.getInfo();
    const id = await Device.getId();
    if (devInfo.platform == "web") {
      await registerWebFirebaseNotifications();
    } else if (devInfo.platform != "android" && devInfo.platform == "ios") {
      await registerMobileFirebaseNotifications();
    }
    await registerFcmOnServer(token, devInfo, id);
  };
  return {
    deregisterFcmOnServer,
    doPushNotifications,
    registerFcmOnServer,
    registerMobileFirebaseNotifications,
    registerWebFirebaseNotifications,
    createSuccessNotification,
    createWarningNotification,
    createErrorNotification,
    createAlarmNotification,
  };
};

export { useNotificationHook };
// export default Notifications;
