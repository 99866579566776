import router from "@/router";
import { useToastStore } from "@/store/toastStore";
import axios from "axios";
import { generateMessageFromError } from "@/utils/errorHandler";
import { useAuthenticationStore } from "@/store/authentication";
import logger from "@/utils/logger";
import { useAppStore } from "@/store/app";

const publicAPI = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL,
  withCredentials: false, // Check cross-site Access-Control
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

const privateAPI = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

privateAPI.interceptors.request.use((restConfig: any) => {
  const authenticationStore = useAuthenticationStore();
  if (authenticationStore.authentication) {
    const token = authenticationStore.authenticationToken;
    const config = restConfig;
    if (config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  }
  return restConfig;
});

privateAPI.interceptors.response.use(
  (response) => response,
  (error) => {
    const authenticationStore = useAuthenticationStore();
    logger.warn(error);
    if (axios.isAxiosError(error)) {
      logger.log("Axios inter: ", error);
      if (error.response && error.response.status == 401) {
        useToastStore().addToast("error", "Login Expired, Please Login Again.");
        authenticationStore.logout();
        authenticationStore.setLoginDialogOpenState(true);
        //router.push({ name: "Account"});
      } else {
        const message = generateMessageFromError(error);
        useToastStore().addToast("error", message);
      }
    } else {
      const message = generateMessageFromError(error);
      useToastStore().addToast("error", message);
    }
    return Promise.reject(error);
  }
);

publicAPI.interceptors.response.use(
  (response) => response,
  (error) => {
    logger.warn(error);

    const message = generateMessageFromError(error);
    useToastStore().addToast("error", message);

    return Promise.reject(error);
  }
);

export { publicAPI, privateAPI };
