const isProduction = process.env.NODE_ENV === "production";

const logger = {
  log: (...args: unknown[]) => {
    // if (!isProduction) {
      console.log(...args);
    // }
  },
  info: (...args: unknown[]) => {
    if (!isProduction) {
      console.info(...args);
    }
  },
  warn: (...args: unknown[]) => {
    if (!isProduction) {
      console.warn(...args);
    }
  },
  error: (...args: unknown[]) => {
    console.error(...args);
  },
};

export default logger;
